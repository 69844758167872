<template lang="pug">
    content-layout.moderator-homepage
        template(v-slot:title-block-left)
            p.text-averta-light-38 {{$t("alertedelements")}}
        template(v-slot:main-content)
            reported-wall.alerted-wrapper.col-9(:selected="selected")
        template(v-slot:aside-content)
            p.subtitle-averta-semibold-18 {{$t("filter")}}
            aside-filter-block.checkbox-filter(:title="$t('Contenus')" :withHr="true" :withDropdown="true")
                template(v-slot:content)
                    checkbox.checkbox(v-for="element in list" :tag="element" :key="element.index" @update="updateSelectedType" :selected="selected.indexOf(element.type) != -1")

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: "moderator-page",
    components: {
        ReportedWall: () => import("skillbase-component/src/components/moderator/components/reported/Wall.vue"),
        AsideFilterBlock: () => import("skillbase-component/src/components/common/layout/block-element/AsideFilterBlock"),
        Checkbox: () => import("skillbase-component/src/components/common/input/Checkbox.vue"),
        ContentLayout: () => import("skillbase-component/src/components/common/layout/ContentLayout.vue"),
    },
    data() {
        return {
            selected: ['post', 'request', 'article', 'group'],
            list: [
                {
                    index: 1,
                    label: this.$t("posts"),
                    type: "post"
                },
                {
                    index: 2,
                    label: this.$t("requests"),
                    type: "request"
                },
                {
                    index: 3,
                    label: this.$t("articles"),
                    type: "article"
                },
                {
                    index: 4,
                    label: this.$t("groups"),
                    type: "group"
                }
            ]
        }
    },
    methods: {
        updateSelectedType(tag) {
            if (this.selected.indexOf(tag.type) == -1) {
                this.selected.push(tag.type)
            } else {
                this.selected = this.selected.filter(el => el != tag.type)
            }
        }
    }
})
</script>

<style scoped lang="scss">
.moderator-homepage {

    .checkbox-filter {
        margin-top: 18px;

        .checkbox {
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>